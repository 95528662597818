import React, { useEffect, useState } from "react";
import SettingsNav from "./settingNav";
import Dashboard from "./dashboard";
import COeqReducer from "./coeqReducer";
import Analytics from "./analytics";

import "./index.scss";
import { useUserData } from "contexts/AuthContextManagement";
import { Spinner } from "react-bootstrap";
import storage from "services/storage";

const TABS = [
  "Dashboard",
  "Analytics",
  "CO2eq Reducer"
];

function Settings() {
  const is_demo = JSON.parse(storage.getItem("is_demo"))
  const { selectedRestaurantId, isRestaurantLoaded, hasRetaurants } = useUserData();
  const [show, setShow] = useState(false)

  const [selectedNav, setSelectedNav] = useState(null);

  useEffect(() => {
    if(!is_demo && isRestaurantLoaded && !hasRetaurants) {
      setShow(false)
      setSelectedNav(TABS[0])
    }
    if(!is_demo && selectedRestaurantId && hasRetaurants) {
      setShow(true)
      setSelectedNav(TABS[0])
    }
    if(is_demo) {
      setSelectedNav(TABS[0])
      setShow(true)
    }
  }, [is_demo, isRestaurantLoaded, hasRetaurants, selectedRestaurantId])

  const selectedNavView = () => {
    switch (selectedNav) {
      case TABS[0]:
        return <Dashboard show={show} />;
      case TABS[1]:
        return <Analytics />;
      case TABS[1]:
          return <COeqReducer />;
      default:
        return <div style={{ width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center"}}> <Spinner animation="border" variant="primary" /></div>;
    }
  };

  return (
      <div className="settings" style={{
          width:"100%"
      }}>
        <SettingsNav
          selectedNav={selectedNav}
          setSelectedNav={setSelectedNav}
          TABS={TABS}
        />
        {selectedNavView()}
      </div>
  );
}

export default Settings;
